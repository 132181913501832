<template>
    <div class="container-fluid">
        <div class="card-info-status">
            <i class="la la-pencil-square text-success">&nbsp;Created </i>
                <br>
            <i class="fa fa-clock text-danger">&nbsp;Expired </i> 
        </div>
        <div class="row ps-scroll height-history-payment " v-for="(payment, index) in listPayment" :key="index">
            <div class="col-6 text-right pr-3 pb-4">
                <span class="font-weight-bold fs-9">{{payment.transactionStatus}}</span>
                 <br>
                 <span>{{payment.paymentType}} 
                     <template v-if="payment.bank">
                         <span class="text-weight-bold">( {{payment.bank}} )</span>
                     </template>
                     </span></div>
            <div class="col-6 text-left border-kres pl-4">
                <span class="span-circle float-left" :class="changeClass(payment.transactionStatus)"></span>
                {{payment.createdDate | formatDate}} <i class="la la-pencil-square text-success"></i>
                <br>
                {{payment.timeExpired | formatDate}}  <i class="fa fa-clock text-danger"></i>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props: {
        payments: { type: Array },
    },
    data(){
        return {
            listPayment: []
        }
    },
    filters: {
        formatDate(date) {
        if(!date) return ''
        return moment(date).format("DD MMMM YYYY, HH:mm");
      },
    },
    mounted(){
        this.listPayment = this.payments
    },
    methods: {
        changeClass(item) {
            if(item == 'pending') {
                return 'bg-dark'
                // return ' kt-list__badge--dark'
            } else if(item == 'settlement') {
                 return 'bg-success'
                // return 'kt-list__badge--success'
            } else if(item == 'cancel') {
                 return 'bg-danger'
                // return 'kt-list__badge--danger'
            } else if(item == 'expire') {
                 return 'bg-warning'
                // return 'kt-list__badge--warning'
            } else {
                 return 'bg-primary'
                // return 'kt-list__badge--metal'
            }
        }
    }
}
</script>

<style>

</style>
